import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import "./style.scss";

import logo from "../images/marbarahona.logo.png";

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false };
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    });
  };

  render() {
    const menuActive = this.state.showMenu ? "is-active" : "";
    const burgerActive = this.state.showMenu ? "is-active" : "";
    return (
      <StaticQuery
        query={graphql`
          query navQuery {
            site {
              siteMetadata {
                menuLinks {
                  name
                  link
                }
              }
            }
          }
        `}
        render={data => (
          <section className="section">
            <nav
              className="navbar is-transparent is-small is-fixed-top container"
              role="navigation"
              aria-label="main navigation"
            >
              <div className="navbar-brand">
                <a className="navbar-item" href="/">
                  <img src={logo} width="200" />
                </a>
                <a
                  role="button"
                  className={`navbar-burger burger ${burgerActive}`}
                  onClick={this.toggleMenu}
                  aria-label="menu"
                  aria-expanded="false"
                  data-target="mainNavbar"
                >
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                </a>
              </div>
              <div className="navbar-end">
                <div id="mainNavbar" className={`navbar-menu ${menuActive}`}>
                  <div className="navbar-start">
                    {data.site.siteMetadata.menuLinks.map(item => (
                      <Link
                        className="navbar-item"
                        activeClassName="is-active"
                        to={item.link}
                      >
                        {item.name.toUpperCase()}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </nav>
          </section>
        )}
      />
    );
  }
}
// </div>

export default Header;
