import React from "react";
import Img from "gatsby-image";

import Layout from "../components/layout";
import Emoji from "../components/emoji";

import "../components/style.scss";
import portrait from "../images/mar.portrait.jpg";

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-5 is-offset-1">
            <figure class="image">
              <img class="" src={portrait} />
            </figure>
            <div className="section content is-smnall">
              <p><Emoji emoji="📞" /><a href="tel:+61415942283">+61 415 942 283</a></p>
              <p><Emoji emoji="✉️" /><a href="mailto:hi@marbarahona.com">hi@marbarahona.com</a></p>
              <p><Emoji emoji="🗺" />4 Alexandra Dr, Camperdown NSW 2050</p>
              <p><Emoji emoji="⏰" />
                Mon – Fri, 9:30am – 7pm. <br/>
                Weekend and evening shoots can be booked.
              </p>
            </div>
          </div>
          <div className="column is-5 content">
            <p className="title">Hi there!</p>
            <p className="subtitle">My name is Maria M. Barahona</p>

            <p>
              I love to capture people’s passion and dedication for their work.
              From offices to carpentry workshops to theatres, I’m fascinated by
              the diversity of experiences and stories I encounter as an events
              photographer.
            </p>
            <p>
              I believe in self development. I’m constantly developing my
              photography skills to provide my cents with creative, powerful
              images. In turn, this helps them better visualise themselves and
              their dreams.
            </p>
            <p>
              My career as a photographer started 15 years ago in a dark room in
              a small town in Spain. Deciding that I was most comfortable behind
              the camera, I went straight to art college to study photography
              and design for 4 years.
            </p>
            <p>
              Working in social and family photography evolved into weddings and
              social events. My natural style is as a “taker” photographer,
              capturing emotions with a cinematic, reportage style. Working for
              a photography company, I also found a new love for portraits.
            </p>
            <p>
              When I’m not capturing events or crafting portraits, you’ll find
              me bushwalking or snorkeling.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
